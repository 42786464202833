// @ts-nocheck
import React from 'react';
import AppLayout from '../components/layout/AppLayout';
import SearchPage from '../components/SearchPage/SearchPage';

const Document = () => {
  return (
    <AppLayout>
      <SearchPage path="/search" />
    </AppLayout>
  );
};
export default Document;
